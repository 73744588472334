/**
 * Assumes `passwordFieldName` is a crispy form generated password field
 */
export const addVisibilityToggle = ($root: JQuery<HTMLElement>, passwordFieldName: string) => {
  const $passwordDiv = $root.find(`#div_id_${passwordFieldName}`);
  const $passwordInput = $root.find(`[name=${passwordFieldName}]`);
  if ($passwordDiv && $passwordDiv.find(".eye-icon-container").length === 0) {
    const $eyeballIconDiv = $("<div></div>").addClass("eye-icon-container");
    $("<i/>")
      .addClass("far fa-eye-slash eye-icon")
      .on("click", function () {
        if ($passwordInput.attr("type") === "password") {
          $passwordInput.attr("type", "text");
          $(this).removeClass("fa-eye-slash").addClass("fa-eye");
        } else {
          $passwordInput.attr("type", "password");
          $(this).removeClass("fa-eye").addClass("fa-eye-slash");
        }
      })
      .appendTo($eyeballIconDiv);
    $eyeballIconDiv.prependTo($passwordDiv);
  }
};
