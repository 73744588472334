import $ from "jquery";
import localForage from "localforage";

import "@force/theme/src/styles";

import { addVisibilityToggle } from "./common";
import "./login.scss";

export interface Identity {
  username: string;
  providers: Array<Provider>;
}

export interface Provider {
  name: string;
  banner_logo: string;
  redirect_url: string;
}

declare global {
  interface Window {
    login: LoginWidget;
  }
}

export default class LoginWidget {
  root: JQuery;
  button: JQuery;
  errors: JQuery;
  username: JQuery;
  password: JQuery;
  remember: JQuery;
  identity: Identity | null | undefined;
  forgotPassword: JQuery;

  constructor(root: string) {
    localForage.config({ name: "force" });
    this.root = $(root);
    this.button = this.root.find("[type=submit]");
    this.errors = this.root.find(".error");
    this.username = this.root
      .find("[name=username]")
      .keydown(this.onUsernameKeyDown.bind(this))
      .change(this.onUsernameChange.bind(this))
      .blur(this.onUsernameChange.bind(this));
    this.password = this.root.find("[name=password]").attr("disabled", "true");
    this.password.closest(".form-group").hide();
    this.forgotPassword = this.root.find("#forgot-password").hide();
    this.remember = this.root.find("[name=remember]");
    this.root.on("submit", this.onSubmit.bind(this));

    // triggers identity check on invalid password so we auto-show
    // the password field on reload
    if (this.username.attr("value") !== undefined) {
      this.doIdentityCheck();
    }

    localForage.getItem("remember", (err, email: string | null) => {
      if (err) {
        return;
      }
      if (email) {
        this.username.val(email).focus();
        this.remember.attr("checked", "true");
      }
    });

    addVisibilityToggle(this.root, "password");
  }

  onUsernameKeyDown(evt: JQuery.Event): void {
    let isWordCharacter = evt.key?.length === 1;
    let isBackspaceOrDelete = evt.keyCode === 8 || evt.keyCode === 46;
    if (isWordCharacter || isBackspaceOrDelete) {
      this.identity = null;
      this.password.attr("disabled", "true").closest(".form-group").hide();
      this.forgotPassword.hide();
      this.button.text("Continue");
    }
  }

  onUsernameChange(): void {
    this.password.focus();
  }

  setRememberCookie(): void {
    if (this.remember.is(":checked")) {
      const username = this.username.val()?.toString();
      if (username) {
        localForage.setItem("remember", username);
      }
    } else {
      localForage.removeItem("remember");
    }
  }

  onSubmit(evt: JQuery.Event): void {
    localForage.removeItem("remember");
    if (this.identity) {
      this.setRememberCookie();
      return;
    }

    evt.preventDefault();
    this.doIdentityCheck();
  }

  doIdentityCheck(): void {
    const val = this.username.val();
    if (val) {
      $.ajax({
        url: `/api/sso/identity/${encodeURIComponent(val.toString())}/`,
        success: this.handleIdentity.bind(this),
        error: () => {
          this.identity = { username: "", providers: [] };
          this.regularLogin();
        },
      });
    }
  }

  handleIdentity(identity: Identity): void {
    this.identity = identity;
    if (identity.providers.length === 0) {
      return this.regularLogin();
    }
    this.ssoLogin(identity);
  }

  regularLogin(): void {
    this.button.text("Log in");
    this.password.removeAttr("disabled").closest(".form-group").slideDown().focus();
    this.forgotPassword.slideDown();
  }

  ssoLogin(identity: Identity): void {
    if (identity.providers.length === 1) {
      const provider = identity.providers[0];
      $("img.logo").attr("src", provider.banner_logo);

      this.root.replaceWith(`
      <h5 class="card-title">Taking you to your organization's sign-in page</h5>
      <p class="card-text">
        <span class="spinner-grow spinner-grow-sm" role="status">
          <span class="sr-only">Loading...</span>
        </span>
        You'll be directed back to Force after signing in through <b class="portal-name">${provider.name}</b>
      </p>
      `);
      const url = new URL(provider.redirect_url, window.location.href);
      const qs = new URLSearchParams(window.location.search);
      const next = qs.get("next");
      if (next) {
        url.searchParams.set("next", next);
      }
      setTimeout(() => (window.location.href = url.toString()), 3500);
    } else {
      $("img.logo").hide();
      this.root.replaceWith(
        "<h5 class='card-title'>Taking you to your organization's sign-in page</h5>" +
          "<p class='card-text'>You'll be directed back to Force after signing in through one of the following providers:</p>" +
          identity.providers
            .map(
              (provider) => `
          <hr />
          <p class="card-text">
            <img class="card-img" src="${provider.banner_logo}">
            <a class="btn btn-primary" href="${provider.redirect_url}">Login using ${provider.name}</a>
          </p>`,
            )
            .join(""),
      );
    }
  }
}

window.login = new LoginWidget("form.login");
